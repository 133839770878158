html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-snap-type: y mandatory;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #000;
  color: #fff;

  &.light-mode {
    background-color: #fff;
    color: #111;
  }

  background-image: url(https://images.unsplash.com/photo-1663970206537-2d24183b59c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1440&q=80);
  background-size: cover;

  --a: 110deg;
  --y: 75%;
}

h1 {
  margin: 24px;
  font-weight: 100;
  color: rgb(0, 120, 163);

  @media (min-width: 768px) {
    img {
      width: 420px;
    }
  }
}

@property --a {
  syntax: '<angle>';
  inherits: true;
  initial-value: 110deg;
}

@property --x {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 50%;
}

@property --y {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 75%;
}

$ridge-gradient: #000000, #020406, #090f13, #111a20, #142631, #173544, #18445a,
  #17546f, #136283, #0c6e94, #04769f, #0179a3;

// $ridge-gradient-overlay: #000000ff, #00000000, #00000000;
$ridge-gradient-overlay: #00000000, #000000ff, #000000ff;

$ridge-gradient-light: #ffffff, #fefefe, #fafafa, #f6f6f6, #efefef, #e9e9e9,
  #e2e2e2, #dbdbdb, #d5d5d5, #d0d0d0, #cdcdcd, #cccccc;

.ridge {
  background-image: conic-gradient(from -110deg at 50% 75%, $ridge-gradient);
  background-attachment: fixed;

  @supports (background: paint(something)) {
    transition: --a 0.6s ease-in-out, --y 0.6s 0.3s ease-in-out;
    background-image: conic-gradient(
      from var(--a) at var(--x) var(--y),
      $ridge-gradient
    );

    &.light-mode {
      background-image: conic-gradient(
        from var(--a) at var(--x) var(--y),
        $ridge-gradient-light
      );
    }
  }
}

@keyframes a {
  0% {
    --a: 110deg;
    // --x: 25%;
    // --y: 75%;
  }
  50% {
    // --x: 75%;
  }
  100% {
    --a: -110deg;
    // --x: 25%;
    // --y: 25%;
  }
}

section {
  flex: 1 0 100vh;
  box-sizing: border-box;
  height: 100vh;
  padding: 32px;
  scroll-snap-align: start;
  //   outline: 3px dotted rgba(red, 0.3);

  -webkit-mask-image: conic-gradient(
    from -10deg at 50% 55%,
    $ridge-gradient-overlay
  );

  @supports (background: paint(something)) {
    transition: --a 0.6s ease-in-out, --y 0.6s 0.3s ease-in-out;
    -webkit-mask-image: conic-gradient(
      from var(--a) at var(--x) calc(var(--y) - 10%),
      $ridge-gradient-overlay
    );
  }

  @media (min-width: 768px) {
    width: 50vw;
    display: flex;
    justify-content: space-between;
    column-gap: 64px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }

  &.home {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  header {
    flex: 1 0 100%;
  }

  h2 {
    font-weight: 100;
  }

  .content {
    @media (min-width: 768px) {
      flex: 1 0 calc(50% - 32px);
    }
  }

  aside {
    @media (min-width: 768px) {
      flex: 1 0 calc(50% - 32px);

      img {
        width: 100%;
      }
    }
  }
}

.global-header {
  position: fixed;
  top: 0;

  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }
}
