html, body {
  height: 100%;
  scroll-snap-type: y mandatory;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  --a: 110deg;
  --y: 75%;
  background-color: #000;
  background-image: url("https://images.unsplash.com/photo-1663970206537-2d24183b59c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1440&q=80");
  background-size: cover;
  flex-direction: column;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  display: flex;
}

body.light-mode {
  color: #111;
  background-color: #fff;
}

h1 {
  color: #0078a3;
  margin: 24px;
  font-weight: 100;
}

@media (min-width: 768px) {
  h1 img {
    width: 420px;
  }
}

@property --a {
  syntax: "<angle>";
  inherits: true;
  initial-value: 110deg;
}

@property --x {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 50%;
}

@property --y {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 75%;
}

.ridge {
  background-image: conic-gradient(from -110deg at 50% 75%, #000, #020406, #090f13, #111a20, #142631, #173544, #18445a, #17546f, #136283, #0c6e94, #04769f, #0179a3);
  background-attachment: fixed;
}

@supports (background: paint(something)) {
  .ridge {
    background-image: conic-gradient(from var(--a) at var(--x) var(--y), #000, #020406, #090f13, #111a20, #142631, #173544, #18445a, #17546f, #136283, #0c6e94, #04769f, #0179a3);
    transition: --a .6s ease-in-out, --y .6s ease-in-out .3s;
  }

  .ridge.light-mode {
    background-image: conic-gradient(from var(--a) at var(--x) var(--y), #fff, #fefefe, #fafafa, #f6f6f6, #efefef, #e9e9e9, #e2e2e2, #dbdbdb, #d5d5d5, #d0d0d0, #cdcdcd, #ccc);
  }
}

@keyframes a {
  0% {
    --a: 110deg;
  }

  100% {
    --a: -110deg;
  }
}

section {
  box-sizing: border-box;
  height: 100vh;
  scroll-snap-align: start;
  flex: 1 0 100vh;
  padding: 32px;
  -webkit-mask-image: conic-gradient(from -10deg at 50% 55%, #0000, #000, #000);
}

@supports (background: paint(something)) {
  section {
    -webkit-mask-image: conic-gradient(from var(--a) at var(--x) calc(var(--y)  - 10%), #0000, black, black);
    transition: --a .6s ease-in-out, --y .6s ease-in-out .3s;
  }
}

@media (min-width: 768px) {
  section {
    width: 50vw;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 64px;
    display: flex;
  }
}

section.home {
  justify-content: center;
  align-items: center;
  display: flex;
}

section header {
  flex: 1 0 100%;
}

section h2 {
  font-weight: 100;
}

@media (min-width: 768px) {
  section .content {
    flex: 1 0 calc(50% - 32px);
  }
}

@media (min-width: 768px) {
  section aside {
    flex: 1 0 calc(50% - 32px);
  }

  section aside img {
    width: 100%;
  }
}

.global-header {
  position: fixed;
  top: 0;
}

.global-header ul {
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.global-header ul li {
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.b812d95d.css.map */
